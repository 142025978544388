.load-more {
  position: relative;

  a.busy {
    background: #005db9;
    cursor: wait;
  }

  a.disabled {
    background-color: #7c858c;
    border-color: #7c858c;
    cursor: not-allowed;

    &:hover{
      color:#fff;
    }
  }

  .load-more-text{
    font-size: 1.5rem;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    margin-top: -10px;
  }
}

.spinner-pulse {
  width: 30px;
  height: 30px;
  margin: auto;
  background-color: #fff;
  border-radius: 100%;
  animation: sk-pulseScaleOut 1s infinite ease-in-out;
  display: none;
}

@keyframes sk-pulseScaleOut {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
