.pagination{
  display: flex;
  flex-flow: column;
  flex-wrap:wrap;
  align-items:flex-end;
  justify-content: center;
  margin-bottom:3rem;

  @media (max-width: 575px) {
    align-items:center;
  }
}

.pagination-list {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left:0;
  padding-left:0;

  > li {
    
    > a {
      @extend .btn;
      @extend .btn-secondary;
      @extend .stada-blue;
      @extend .bg-transparent;
      @extend .white-hover;
      @extend .bg-stada-blue-hover;
      @extend .bc-stada-blue;
      min-width: 0;
      min-height: 0;
      padding: 3px 1rem;
      margin: 0.2rem;
    }

    &.page-number {

      @media (max-width: 575px) {
        display: none;
      }

      > a{
        min-width: 35px;
      }

    }

    &.disabled > a{
      cursor:default;
    }

    &.active > a{
      @extend .bg-stada-blue;
      @extend .white;
    }
    
  }

}

.pagination-prev > .pagenav:before {
  content: "<";
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
}
.pagination-next > .pagenav:before {
  content: ">";
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  line-height: 1;
}

.pagination-prev, .pagination-next{

  > a > span{
    display: none;
  }

}
