@font-face {
  font-family: "NotoSans";
  src: url("../fonts/NotoSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NotoSans";
  src: url("../fonts/NotoSans-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "NotoSans";
  src: url("../fonts/NotoSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "NotoSans";
  src: url("../fonts/NotoSans-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Blair";
  src: url("../fonts/Blair-ITC-Std.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}
