* {
  font-family: 'NotoSans', sans-serif;
}

html {
  scroll-behavior: smooth;
}

input.form__input::placeholder {
  opacity: 0.5;
}

.btn-primary,
.btn-secondary,
.form-section input[type='submit'],
.hp__section .hp__section__statistic-wrapper .hp__section__button,
.main-subtitle,
.blair {
  font-family: 'Blair';
}

.bg-light-grey {
  background-color: rgb(236, 236, 236);
}

.notosans {
  font-family: 'NotoSans';
}

a.btn {
  width: fit-content;
  width: -moz-fit-content;
}

.mta {
  margin-top: auto;
}

.tdn {
  text-decoration: none;
}

.miwa {
  min-width: auto;
}

.miha {
  min-height: auto;
}

.bg-stada-gradientBlue {
  background: rgb(69, 128, 208);
  background: linear-gradient(0deg, rgba(69, 128, 208, 1) 0%, rgba(98, 154, 228, 1) 100%);
}

h1,
h2,
h3:not(.footer__title),
h4,
h5,
h6,
.fw5,
.product-details__accordion .accordion-title,
.product-details__select select,
.report-2020 .banner-title,
.report-2020 .grid-section-heading,
.t-title {
  font-weight: bold !important;
}

.homepage:not(.reserved) .sidebar__indicator {
  -ms-flex: 0 0 25px;
  flex: 0 0 25px;
  width: 24px;
  height: 24px;
  border: 1px solid black;
  border-radius: 100%;
  display: grid;
  place-items: center;
}

.sidebar__indicator.play {
  z-index: 999;
  -ms-flex: 0 0 55px;
  flex: 0 0 55px;
  width: 55px;
  height: 55px;
  border: 2px solid white;
  border-radius: 100%;
  display: grid;
  place-items: center;
  cursor: pointer;
  margin-left: 1rem;
  transition: all 0.1s ease-in-out;
}

.sidebar__indicator.play:hover {
  background-color: white;
}

.sidebar__indicator.play:hover svg path {
  fill: #d50058;
}

body {
  overflow-x: hidden;
}

.ph0 {
  padding-left: 0;
  padding-right: 0;
}

.small {
  font-size: 0.75rem;
  line-height: 2;
}

.container {
  max-width: unset;
}

.header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-secondary-logo {
  align-self: flex-start;
}

.menu-container {
  display: flex;
  align-items: center;
}

.main-menu li.nav__item a {
  text-transform: uppercase;
  text-decoration: none;
}

.mega-menu {
  position: absolute;
  width: 100vw;
  left: 0;
  z-index: 9;
  overflow-y: hidden;
  top: 175px;
}

.main-menu .mega-menu {
  top: 115px;
}

.mega-menu img,
.formazione-single_cat img {
  object-fit: cover;
  max-height: 270px;
}

.formazione-single_cat img,
body.homepage.reserved .newsSwiper figure,
.newsSwiper .swiper-slide img {
  border: 1px solid lightgray;
}

.nav__link-third {
  min-height: 40px !important;
}

.nav-offcanvas {
  overflow-x: hidden !important;
  width: 100%;
  height: 0;
  min-height: 100%;
  transform: translateX(100%);
}

.mainSwiper .swiper-button-next:after {
  font-size: 20px;
  margin-left: 3px;
}

.mainSwiper .swiper-button-prev:after {
  font-size: 20px;
  margin-right: 3px;
}

.mainSwiper .swiper-button-prev,
.mainSwiper .swiper-button-next {
  color: white;
  opacity: 1;
  width: 45px;
  border: 1px solid white;
  height: 45px;
  border-radius: 100px;
}

.mainSwiper .swiper-button-prev,
.mainSwiper .swiper-container-rtl .swiper-button-next {
  left: 30px;
}

.mainSwiper .swiper-button-next,
.mainSwiper .swiper-container-rtl .swiper-button-prev {
  right: 30px;
}

.banner__overlay .border-bottom,
.contactBanner .border-bottom,
.singleBanner .border-bottom {
  border-bottom: 1px solid lightgray;
}

.contactBanner .search-products {
  margin: auto;
}

.contactBanner .search {
  max-width: 500px;
}

header .search__input {
  width: unset;
}

.search.text-overlay__body {
  width: 100%;
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.contactBanner .btn-search {
  position: absolute;
  width: 28px;
  height: 28px;
  top: 8px;
  right: 0;
  margin-top: 9px;
}

.contactBanner .btn-search img {
  display: block !important;
}

.contactBanner .col-24 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center right;
}

.banner--text-overlay .banner__box {
  padding: 28px 20px 28px;
}

.newsSwiper .swiper-button-next:after,
.storiesSwiper .swiper-button-next:after,
.brandSwiper .swiper-button-next:after,
.homepage .products .swiper-button-next:after {
  font-size: 20px;
  margin-left: 3px;
}

.newsSwiper .swiper-button-prev:after,
.storiesSwiper .swiper-button-prev:after,
.brandSwiper .swiper-button-prev:after,
.homepage .products .swiper-button-prev:after {
  font-size: 20px;
  margin-right: 3px;
}

.newsSwiper .swiper-button-prev,
.newsSwiper .swiper-button-next,
.homepage .products .swiper-button-prev,
.homepage .products .swiper-button-next {
  position: relative;
  color: #d50058;
  opacity: 1;
  width: 45px;
  border: 1px solid #d50058;
  height: 45px;
  border-radius: 100px;
  top: 11.25px;
}

.storiesSwiper .swiper-button-prev,
.storiesSwiper .swiper-button-next,
.brandSwiper .swiper-button-prev,
.brandSwiper .swiper-button-next {
  color: #b0b1b1;
  border: 1px solid #b0b1b1;
  position: relative;
  opacity: 1;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  top: 11.25px;
}

.newsSwiper .swiper-button-prev,
.newsSwiper .swiper-button-next,
.homepage .products .swiper-button-prev,
.homepage .products .swiper-button-next {
  color: #005db9;
  border: 1px solid #005db9;
}

.newsSwiper .swiper-button-prev,
.storiesSwiper .swiper-button-prev,
.brandSwiper .swiper-button-prev,
.homepage .products .swiper-button-prev {
  cursor: pointer;
  left: -10px;
}

.newsSwiper .swiper-button-next,
.storiesSwiper .swiper-button-next,
.brandSwiper .swiper-button-next,
.homepage .products .swiper-button-next {
  cursor: pointer;
  right: -10px;
}

.newsSwiper *,
.storiesSwiper *,
.advanced-blog-landing a,
.registration-form span a {
  text-decoration: none;
}

.newsSwiper .date .behind,
.advanced-blog-landing .date .behind {
  background-color: #1b5dba;
  opacity: 0.3;
  width: 100px;
  height: 115%;
  border-radius: 50%;
  transform: translate(-45%, -15px);
}

.newsSwiper .date .front,
.advanced-blog-landing .date .front {
  background-color: #1b5dba;
  opacity: 0.75;
  width: 400px;
  height: 275px;
  border-radius: 50%;
  transform: translate(-60%, -67%);
  position: absolute;
  top: 0;
}

.newsSwiper .date .span,
.advanced-blog-landing .date .span {
  position: absolute;
  top: 8px;
  left: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
}

.newsSwiper .date .span .day,
.advanced-blog-landing .date .span .day {
  font-size: 1.75rem;
}

.storiesSwiper .swiper-slide {
  transform: scale(1);
  transition: transform 0.2s ease-in-out;
}

.storiesSwiper .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-duplicate-active) {
  transform: scale(0.8);
}

.storiesSwiper .swiper-slide:not(.swiper-slide-active):not(.swiper-slide-duplicate-active) img {
  filter: grayscale(100%);
}

.healthSwiper {
  width: 100%;
}

.healthSwiper .swiper-slide {
  max-height: 400px;
  display: flex;
}

.healthSwiper .swiper-slide img {
  object-fit: cover;
  width: 100%;
}

.swiper-slide figure.card-image img {
  object-fit: cover;
}

.newsSwiper .swiper-slide .pa3,
.advanced-blog-landing .card .pa3 {
  padding-left: 0px;
}

.timeline .swiper-wrapper {
  box-sizing: border-box;
}

.timeline-nav {
  border: 0px;
}

.timeline-items .timeline-item:nth-of-type(odd) .node--img img,
.timeline-items .timeline-item:nth-of-type(2n) .node--img img {
  transform: rotate(-4deg);
}

.timeline-items .timeline-item:nth-of-type(odd) .node--img img {
  transform: rotate(4deg);
}

.timeline-item .node--copy,
.timeline-item .node--img {
  padding: 40px;
}

.img-up {
  position: absolute;
  width: 450px;
  right: 0;
  transform: translate(40%, -50%);
  border: 7px solid white;
}

.textleft .img-up {
  left: 0;
  top: 0;
  transform: translate(0%, 0%);
}

.textright .img-up {
  bottom: 0;
  transform: translate(0%, 0%);
}

.banner__box {
  background-color: transparent !important;
}

.product-page .banner__box,
.formazione-category .banner__box {
  background-color: rgba(248, 248, 248, 0.9) !important;
}

.banner__pattern.right {
  right: -40%;
  width: 1000px;
  height: 500px;
  top: -15%;
}

.text-overlay .measure-wide {
  position: relative;
  z-index: 999;
}

.text-overlay img {
  display: none;
}

.homepage .products img {
  position: absolute;
  z-index: 999;
  top: -10%;
  left: 50%;
  transform: translateX(-50%);
}

.homepage .products .banner--text-overlay,
.homepage .products .over-hidden,
.homepage .products .over-hidden .flex.align-center {
  min-height: 450px;
}

.homepage .products .mr4 {
  margin-top: 27.5rem;
  margin-right: 0rem;
  padding: 2rem;
}

.homepage .products .flex-md.align-center {
  margin-top: 5rem;
}

.bg-gradient-blue {
  background: rgb(82, 159, 237);
  background: linear-gradient(
    90deg,
    rgba(82, 159, 237, 1) 0%,
    rgba(54, 136, 219, 1) 50%,
    rgba(29, 119, 204, 1) 100%
  );
}

.bg-blue {
  background-color: #1b5dba;
}

footer a {
  text-decoration: none;
}

footer .footer__contact-link {
  margin-bottom: 0.75rem;
}

header a.btn-secondary {
  display: none;
}

.timeline-nav-item:not(.is-selected) {
  background-color: transparent;
}

.timeline-nav-item:not(.is-selected) .timeline-nav-link {
  color: #005db9;
}

.timeline-nav-link {
  font-family: 'Blair';
  cursor: pointer;
}

.timelineSwiper .swiper-wrapper,
.timelineVerticalSwiper .swiper-wrapper {
  height: fit-content;
  height: -moz-fit-content;
}

.timelineVerticalSwiper .timeline-item {
  opacity: 1;
}

.timelineItemsSwiper .timeline-item {
  flex-wrap: nowrap;
}

.timelineItemsSwiper .swiper-slide:not(.swiper-slide-active),
.homepage .productSwiper .swiper-slide:not(.swiper-slide-active),
.swiperSubcat .swiper-slide:not(.swiper-slide-active) {
  opacity: 0 !important;
}

.swiperSubcat .swiper-slide.swiper-slide-active {
  opacity: 1 !important;
}

.product_subcat .subcat_nav a {
  padding: 20px 30px;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.product_subcat .subcat_nav a.is-active {
  background-color: #f8f8f8;
}

// @media (max-width: 1100px) {
//   .product_subcat .subcat_nav {
//     display: none;
//   }
// }

.work-with-us_alert_content {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.4);
  bottom: 0;
  z-index: 9999;
  right: 0;
}

.cookie-directive,
.work-with-us_alert {
  position: fixed;
  width: calc(100vw - 30px);
  max-width: 500px;
  bottom: 15px;
  right: 15px;
  animation-name: enterBottom;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  z-index: 999;
  background-color: white;
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.work-with-us_alert {
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  animation-name: unset;
}

.cookie-directive.is-closing {
  animation-name: leaveBottom;
}

.cookie-directive-options {
  display: none;
}

.video-preview-player.overlay {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0, 0, 0, 0.85);
  display: grid;
  place-items: center;
  z-index: 99999;
}

.video-preview-player.dn {
  display: none;
}

.video-preview-player iframe {
  width: 85vw;
  max-width: 1000px;
}

.advanced-blog-landing .card-image img {
  object-fit: cover;
}

/* Product Filters Start ==*/

.product_filters .btn.active,
.product_filters .single_letter.active {
  background-color: #005db9;
  color: white;
}

.product_filters .single_letter {
  text-decoration: none;
  font-family: 'Blair';
  font-size: 0.85rem;
  width: 26px;
  height: 26px;
  border: 1.9px solid #005db9;
  border-radius: 50%;
  display: grid;
  place-items: center;
  line-height: 1.85;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.product_filters .search {
  max-width: 100%;
}

.product_filters .search__input {
  min-height: 40px;
  height: 40px;
}

.product_filters img {
  min-height: 35px;
  height: 35px;
}

.product_results .container,
.product_results-private .container,
.our-brands .brands,
.search-results {
  gap: 30px;
}

.product_results .single_product-result {
  padding: 1rem 1.5rem;
  padding-bottom: 0rem;
  border: 2px solid rgb(236, 236, 236);
  min-height: 240px;
}

.search-result-single {
  padding: 1rem 1.5rem;
  border: 2px solid rgb(236, 236, 236);
}

.product_results-private .single_product-result {
  padding-bottom: 0rem;
  border: 2px solid rgb(236, 236, 236);
}

.product_results .single_product-result p {
  margin-bottom: 0px;
}

.product_results a,
.product_results-private a,
.search-results a {
  text-decoration: none;
}

.product_results h2,
.search-results h2 {
  font-size: 1.75rem;
}

/* Product Filters End ====*/

/* Registration form Start ===*/

.login-form .form__row {
  margin-bottom: 20px;
}

.login-form .register-block {
  border: 2px solid #efefef;
}

.registration-form .form__row,
.contact-form .form__row,
.login-form .container.flex {
  gap: 30px;
}

.registration-form .col-12,
.contact-form .col-12 {
  gap: 30px;
}

.registration-form .form__select.w-50-md:after,
.contact-form .form__select.w-50-md:after {
  right: 2.25rem;
}

// .registration-form input[type="submit"]:not(.validate) {
//   font-size: 1.75rem;
//   padding: 1.5rem 5rem;
// }

.registration-form .form__checkbox-group *,
.contact-form .form__checkbox-group *,
.form__checkbox-group__item * {
  font-size: 0.92rem;
  line-height: 1.4;
}

/* Registration Form End =====*/

.flex.formazione-single_cat {
  // padding: 30px 0px;
  border-bottom: 1px solid #d8d8d8;
  .item-desc {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .item-attach {
    border-left: solid 1px #d8d8d8;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-top: 20px;
  }
}

.icon-custom {
  width: 30px;
  margin-left: 15px;
  display: inline-block;
  min-width: 30px;
  border: 0 !important;
}

.story-content > *:not(.quote):not(.video-section) {
  max-width: 45em;
}

.aspect-ratio__object {
  z-index: 999;
}

.quote--dark blockquote:before,
.quote--dark blockquote:after {
  background-repeat: no-repeat;
}

.health-report_content * {
  max-width: 55em;
}

@media (max-width: 950px) {
  .health-report_content * {
    width: 100%;
  }
}

.health-report_content .manPic {
  width: 200px;
  height: 200px;
  border-radius: 100px;
  overflow: hidden;
}

.health-report_content ul {
  padding-left: 20px;
}

.health-report_content li {
  margin-bottom: 20px;
}

.health-nav_container.isSticky {
  position: fixed;
  z-index: 999;
  top: 0;
  width: 100%;
}

.health-nav_menu {
  margin-left: auto;
  margin-right: auto;
}

.health-nav_menu a {
  padding: 20px 20px;
  text-decoration: none;
  font-weight: bold;
}

.banner--blockquote .banner__blockquote__source--vector:before {
  content: none;
}

/* Search results start ===*/

.search-results #searchForm strong {
  font-family: 'Blair', sans-serif;
}

.search-results .form__checkbox-group *,
.search-results .form-check,
.search-results #searchForm strong {
  color: white;
}

.search-results input:not([type='radio']),
.search-results select {
  min-height: 60px;
  height: 60px;
  outline: none;
  transition: color 0.2s ease-in, border-color 0.2s ease-in;
  padding: 0rem 1rem;
  border-width: 1px;
  border-color: #f8f8f8;
}

.search-results input:focus {
  border-color: #005db9;
}

/* Search results end =====*/

#farmacia-selezionata.dn,
.registration-form .hidden {
  display: none !important;
}

header .main-logo img {
  max-height: 84px;
}

header .self-start img {
  max-width: 125px;
}

.mega-menu__list__link:hover {
  font-weight: 200;
  border-bottom: 1px solid black;
}

.mega-menu__list__link:hover p {
  text-decoration: underline;
  width: fit-content;
  margin-left: auto;
}

p {
  line-height: 1.5;
}

.homepage .products .swiper-button-disabled {
  color: gray;
  border-color: gray;
  opacity: 0.75;
}

@media (min-width: 576px) {
  .flex-nowrap-xs {
    flex-wrap: nowrap;
  }
  .col-9-xs {
    -ms-flex: 0 0 37.5%;
    flex: 0 0 37.5%;
    max-width: 37.5%;
  }
  .col-15-xs {
    -ms-flex: 0 0 62.5%;
    flex: 0 0 62.5%;
    max-width: 62.5%;
  }
  .order-0-xs {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-1-xs {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media (min-width: 650px) {
  .col-115-sm {
    -ms-flex: 0 0 calc(50% - 15px);
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
  .banner--hs .banner-title,
  .banner--hs .banner__title {
    line-height: 60px;
  }
}

@media (max-width: 768px) {
  .banner__video .banner__pattern {
    right: -9.333% !important;
  }
}

@media screen and (min-width: 950px) {
  .bg-center-md {
    background-position: center;
  }
  .flex-nowrap-md {
    flex-wrap: nowrap;
  }
  .w-90-md {
    width: 90%;
  }
  .f-25-md {
    font-size: 1.75rem;
  }
  .w-auto-md {
    width: auto;
  }
  .col-115-md {
    -ms-flex: 0 0 calc(50% - 15px);
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
  .col-auto-md {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
    max-width: 49%;
  }
  .homepage .products .mr4 {
    margin-top: 0rem;
    margin-left: 27.5rem;
    margin-right: inherit;
  }
  .homepage .products img {
    top: -20%;
    left: 40px;
    transform: translateX(0%);
  }
  .homepage .products .sidebar__link.is-selected .sidebar__label,
  .homepage .products .sidebar__link:hover .sidebar__label {
    color: #005db9;
  }
  .homepage .products .sidebar__link.is-selected .sidebar__indicator,
  .homepage .products .sidebar__link:hover .sidebar__indicator {
    border-color: #005db9;
  }
  .homepage .products .sidebar__link.is-selected .sidebar__indicator svg,
  .homepage .products .sidebar__link:hover .sidebar__indicator svg {
    fill: #005db9;
  }
  header a.btn-secondary {
    display: flex;
  }
  .timelineItemsSwiper .item-node {
    padding: 0px;
  }
  .timeline-item .node--img img {
    margin-top: 15px;
  }
  .banner--text-overlay .banner__box {
    padding: 40px 70px;
  }
}

@media screen and (max-width: 950px) {
  .search,
  .sidebar {
    max-width: 100%;
  }
  .contactBanner .measure-wide {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .timelineItemsSwiper .timeline-item {
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media (min-width: 950px) {
  .form__row .col-auto-md {
    max-width: 516px;
  }
  .ph4-md {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .order-0-md  {
    -webkit-box-ordinal-group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
  .order-1-md {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
  .banner__box.pr0 {
    padding-right: 0px !important;
  }
  .banner__box.pl0 {
    padding-left: 0px !important;
  }
}

@media screen and (max-width: 1440px) {
  .img-up {
    width: 350px;
    top: 50px;
  }
}

@media screen and (min-width: 1240px) {
  .text-overlay img {
    display: block;
  }
}

@media screen and (max-width: 1250px) {
  .banner--text-overlay.banner__overlay {
    padding: 0px;
  }

  footer .white-logo {
    margin-left: auto;
    margin-right: auto;
  }

  .mainSwiper .swiper-button-next,
  .mainSwiper .swiper-container-rtl .swiper-button-prev {
    top: 90%;
    right: calc(45% - 27.5px);
  }
  .mainSwiper .swiper-button-prev,
  .mainSwiper .swiper-container-rtl .swiper-button-next {
    top: 90%;
    left: calc(45% - 27.5px);
  }
}

@media (min-width: 1250px) and (max-width: 1500px) {
  .textleft .banner--text-overlay.banner__overlay,
  .textright .banner--text-overlay.banner__overlay {
    padding: 50px;
  }
}

@media (min-width: 1250px) {
  .banner--blockquote .quote blockquote:after {
    top: calc(100% - 10px);
    right: -100px;
  }

  .single_product-result.col-115-xl {
    -ms-flex: 0 0 calc(50% - 15px);
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
}

@media (max-width: 1350px) {
  .user_nav .hide {
    display: none;
  }
}

@media screen and (min-width: 1500px) {
  // .timeline-nav-wrapper .js-timeline-nav-button.previous,
  // .timeline-nav-wrapper .js-timeline-nav-button.next {
  //   display: none;
  // }
  .pr4-xl {
    padding-right: 2rem;
  }
  .mr4-xl {
    margin-right: 2rem;
  }
  .mr5-xl {
    margin-right: 4rem;
  }
  .df-xl {
    display: flex;
  }
  .mb0-xl {
    margin-bottom: 0rem;
  }
  .dn-xl {
    display: none;
  }
  .db-xl {
    display: block;
  }
  .col-18-xl {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-6-xl {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  div:not(.single_product-result).col-115-xl {
    -ms-flex: 0 0 calc(50% - 15px);
    flex: 0 0 calc(50% - 15px);
    max-width: calc(50% - 15px);
  }
  .homepage .products .flex-md.align-center {
    margin-top: 0rem;
  }
  .homepage .banner--short .banner__pattern,
  .homepage .banner--tall .banner__pattern,
  .homepage .banner__pattern:not(.right) {
    right: 54% !important;
  }
}

@media (max-width: 1600px) {
  .nav-private .nav__item.ml4 {
    margin-left: 1rem;
  }
}

@media (min-width: 1600px) {
  .mha-xxl {
    margin-left: auto;
    margin-right: auto;
  }
  .w-80-xxl {
    width: 80%;
  }
}

@media screen and (min-width: 1750px) {
  .mla-xl {
    margin-left: auto;
  }
}

@keyframes enterBottom {
  from {
    transform: translateY(30%);
    opacity: 0;
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}

@keyframes leaveBottom {
  from {
    transform: translateY(0%);
    opacity: 1;
  }
  to {
    transform: translateY(30%);
    opacity: 0;
  }
}
