#system-message-container {
  @extend .banner__overlay;
  @extend .mla;
  @extend .mra;
  margin-bottom: 2rem;
}

#system-message {
  @extend .container-medium;
  @extend .mla;
  @extend .mra;
  @extend .ph0;
  @extend .ph4-md;
  .alert-heading,
  a.close {
    display: none;
  }

  .alert-text {
    @extend .validate-error;
  }

  .alert-message {
    .alert-text {
      color: #005db9;

      &:before {
        font-family: unset;
        content: "";
        width: 16px;
        height: 16px;
        top: 2px;
        background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2033%2024%22%3E%0A%20%20%20%20%20%20%20%20%3Cpath%20fill%3D%22%23005db9%22%20fill-rule%3D%22evenodd%22%20d%3D%22M28.922.59c.393-.393.846-.59%201.358-.59.511%200%20.964.197%201.357.59.354.354.531.797.531%201.328%200%20.532-.177.974-.53%201.328l-18.83%2018.947-.118.118-.118.118c-.354.394-.797.59-1.328.59s-.974-.196-1.328-.59L.531%2013.103C.177%2012.75%200%2012.307%200%2011.775c0-.53.177-.974.531-1.328.394-.393.846-.59%201.358-.59.511%200%20.964.197%201.357.59l7.969%207.969L28.922.59z%22%3E%3C%2Fpath%3E%0A%20%20%20%20%20%20%3C%2Fsvg%3E);
        background-repeat: no-repeat;
      }
    }
  }
}

// .alert-link,
// .close {
//   font-weight: 700;
// }
// .alert {
//   padding: 0.75rem 1.25rem;
//   margin-bottom: 1rem;
//   border: 1px solid transparent;
//   border-radius: 0.25rem;
// }
// .alert-heading {
//   color: inherit;
//   font-size: 1.5rem;
//   margin-bottom: 0.75rem;
// }
// .alert .close {
//   float: right;
//   font-size: 1.5rem;
//   line-height: 1;
//   color: #000;
//   text-shadow: 0 1px 0 #fff;
//   opacity: 0.5;
//   cursor: pointer;
// }
// .alert-dismissible .close {
//   position: relative;
//   top: -0.75rem;
//   right: -1.25rem;
//   padding: 0.75rem 1.25rem;
//   color: inherit;
// }
// .alert-success {
//   background-color: #dff0d8;
//   border-color: #d0e9c6;
//   color: #3c763d;
// }
// .alert-success hr {
//   border-top-color: #c1e2b3;
// }
// .alert-success .alert-link {
//   color: #2b542c;
// }
// .alert-info {
//   background-color: #d9edf7;
//   border-color: #bcdff1;
//   color: #31708f;
// }
// .alert-info hr {
//   border-top-color: #a6d5ec;
// }
// .alert-info .alert-link {
//   color: #245269;
// }
// .alert-warning {
//   background-color: #fcf8e3;
//   border-color: #faf2cc;
//   color: #8a6d3b;
// }
// .alert-warning hr {
//   border-top-color: #f7ecb5;
// }
// .alert-warning .alert-link {
//   color: #66512c;
// }
// .alert-danger {
//   background-color: #f2dede;
//   border-color: #ebcccc;
//   color: #a94442;
// }
// .alert-danger hr {
//   border-top-color: #e4b9b9;
// }
// .alert-danger .alert-link {
//   color: #843534;
// }
// .alert-error {
//   background-color: #f2dede;
//   border-color: #f3c9c9;
//   color: #bc312f;
// }
// .alert-error hr {
//   border-top-color: #e4b9b9;
// }
// .alert-error .alert-link {
//   color: #843534;
// }
