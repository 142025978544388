@import "social-bar";
@import "tags";

.article-footer{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  @extend .bg-light-grey;

  .article-info.muted{
    display: none;
  }
}