body {
  padding-top: 160px;

  header {
    height: unset;

    .mega-menu {
      top: 160px;
    }

    .main-logo img {
      max-width: 200px;
    }

    .search {
      position: relative;
      width: unset;

      .search__input {
        min-height: 40px;
      }

      .btn-search {
        position: absolute;
        right: 10%;

        // img {
        //   width: 50%;
        // }
      }
    }

    .nav-offcanvas__header {
      .search {
        align-items: center;

        img {
          width: 100%;
        }
      }

      .search__input {
        width: 100%;
      }
    }
  }
}

@media (min-width: 1500px) {
  .banner__pattern:not(.right) {
    right: 41% !important;
  }
}

@media (min-width: 950px) and (max-width: 1250px) {
  header {
    .self-start img {
      max-width: 100px;
    }
    .f5 {
      font-size: 1rem;
    }
  }
  .homepage {
    .banner__image {
      padding-bottom: 450px;
    }
    .banner__pattern:not(.right) {
      right: 35% !important;
    }
  }
}

@media (min-width: 1250px) and (max-width: 1500px) {
  .banner__pattern:not(.right) {
    right: 23% !important;
  }
  .homepage .banner__pattern:not(.right) {
    right: 46% !important;
  }
}

@media (min-width: 950px) and (max-width: 1100px) {
  header {
    a.btn.btn-secondary {
      font-size: 1.25rem;
      min-width: 120px;
      i {
        display: block !important;
      }
      span {
        display: none;
      }
    }
  }
}

@media (max-width: 650px) {
  .mainSwiper {
    background-color: "red";

    .banner__pattern {
      right: 24%;
    }

    .banner__text {
      background-color: "red";
      .lh-copy {
        background-color: "red";
        padding-top: 0px;

        p {
          display: none;
        }
      }
    }
  }

  .singleBanner {
    .banner__text {
      margin-right: 16vw;

      p {
        font-size: 0.95rem;
        line-height: 1.25;
      }
    }
  }
}

@media (max-width: 400px) {
  .mainSwiper {
    .banner__pattern {
      right: 14%;
    }
    .btn.btn-primary  {
      font-size: 0.75rem;
      padding: 0;
    }
    .banner-title {
      font-size: 1.25rem !important;
    }
  }
}
