$social-colors: ( 
    facebook: (#1B4E90), 
    twitter: (#01A5ED), 
    linkedin: (#0077B5),  
    whatsapp: (#79BA7E),
    telegram: (#179CDE),
	  instagram: (#cd486b),
    mail: (#e6e6e6)
);

.social-bar {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	align-items: center;
	
	button {
		border: 0;
		width: 44px;
		height: 40px;
		cursor: pointer;
		transition: background 0.2s linear;

    @media (max-width:649px){
      width:25%;
    }

		.share-btn & {
			display: none !important;
		}

		.share-btn.active & {
			display: inline-block !important;
		}
	}

	@each $social, $color in $social-colors {

		button[data-sharer="#{$social}"] {
			background: $color;
			color: #fff;

			&:hover {
				background: darken($color, 10%);
			}
		}
	}
}



