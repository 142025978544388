.timelineVerticalSwiper .timeline-item {
  opacity: 0.3;
  transition: opacity 0.35s ease-in-out;
}

.timelineVerticalSwiper .timeline-item.in_view {
  opacity: 1;
}

.leftAnim.start__animation {
  animation: leftToRight 0.7s ease-out;
}
.rightAnim.start__animation {
  animation: rightToLeft 0.7s ease-out;
}

@keyframes leftToRight {
  0% {
    opacity: 0;
    transform: translateX(-30rem);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes rightToLeft {
  0% {
    opacity: 0;
    transform: translateX(30rem);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
