#dati-farmacia{
  input[type="submit"]:not(.db){
    display: none;
  }
}

#farmacia-selezionata {
  margin-bottom:0 !important;
  .validate {
    font-size: 1.3rem;
    text-align: center;
    width: 100%;
    padding-top: 0rem;
    position: relative;

    .validate-message {
      font-weight: bold;
      margin-bottom: 0.5rem;
    }

    &.validate-ok {
      .validate-message {
        color: #005db9;
      }
    }

    &.validate-ko {
      .validate-message,
      i {
        color: #d50058;
      }

      i {
        font-size: 3rem;
        margin-bottom: 0.5rem;
      }
    }

    .validate-object {
      color: #7c858c;
    }

    svg {
      display: unset;
      max-width: unset;
      height: unset;
    }
  }
}

.validate-error {
  color: #d50058;
  font-size: 1rem;
  text-align: left;
  margin-bottom: 0.5rem;
  padding-left: 1.5rem;
  position: relative;

  &:before{
    position: absolute;
    top: 1px;
    left: 0;
    font-family: FontAwesome;
    content: "\f071";
    font-size: 0.9rem;
  }
}
