ul.tags{
  margin: 0;
  padding: 0;
  list-style: none;

  @media (max-width: 649px){
    margin-bottom:2rem;
    border-bottom: 1px solid #d8d8d8;
    padding-bottom: 2rem;
  }

  li{
    display: inline-block;

    a{
      padding: 3px 5px;
      background-color: #d8d8d8 ;
      border: 1px solid darken(#d8d8d8, 10%);
      border-radius: 3px;
      text-decoration:none;
      @extend .f8;
      margin: 0.1rem 0;
      display: block;
      padding-left: 20px;
      position:relative;

      &:before{
        position: absolute;
        top: 2px;
        left: 4px;
        font-family: FontAwesome;
        content: "\f02b";
        color: #797979;
        font-size: 0.9rem;
      }
    }
  }
}