.checkbox-switch-list {
	--white: #e8e9ed;
	--gray: #f0f0f0;
	--blue: #18172c;
	--green: #00d084;
	--pink: #ff4b77;

	list-style: none;
	padding: 0;
	margin: 0;
	margin-bottom: 1rem;
	border-radius: 5px;
	//color: var(--white);

	* {
		padding: 0;
		margin: 0;
	}
	
	a {
		color: inherit;
		text-decoration: none;
	}
	
	label {
		cursor: pointer;
	}
	
	[type="checkbox"] {
		position: absolute;
		left: -9999px;
	}
	
	h1 {
		font-size: 2rem;
		text-align: center;
	}
	
	li {
		position: relative;
		counter-increment: switchCounter;
	}
	
	li:not(:last-child) {
		border-bottom: 1px solid var(--gray);
	}
	
	
	label {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		padding: 15px;
	}

	span:first-child {
		padding-right:1rem;
	}
	
	span:last-child {
		position: relative;
		width: 50px;
		height: 26px;
		border-radius: 15px;
		//box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.4);
		background: var(--gray);
		transition: all 0.3s;
		flex: 0 0 50px;
	}
	
	span:last-child::before,
	span:last-child::after {
		content: "";
		position: absolute;
	}
	
	span:last-child::before {
		left: 1px;
		top: 1px;
		width: 24px;
		height: 24px;
		background: #a8a8a8;
		border-radius: 15px;
		z-index: 1;
		transition: transform 0.3s;
	}
	
	span:last-child::after {
		top: 50%;
		right: 8px;
		width: 12px;
		height: 12px;
		transform: translateY(-50%);
		background: url(../img/uncheck-switcher.svg);
		background-size: 12px 12px;
	}
	
	[type="checkbox"]:checked + label span:last-child {
		background: var(--green);
	}
	
	[type="checkbox"]:checked + label span:last-child::before {
		transform: translateX(24px);
		background: #fff;
	}
	
	[type="checkbox"]:checked + label span:last-child::after {
		width: 14px;
		height: 14px;
		/*right: auto;*/
		left: 8px;
		background-image: url(../img/checkmark-switcher.svg);
		background-size: 14px 14px;
	}
	
	@media screen and (max-width: 600px) {
		li::before {
			display: none;
		}
	}
}


